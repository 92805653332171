import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import $ from "jquery";
import OutsideClickHandler from "react-outside-click-handler";

import Logo2 from "./logo2";
import AccessibilityMenu from "./AccessibilityMenu";

import t from "../locale";

const Header = ({
	isHome,
	lang,
	translationPL,
	translationEN,
	translationDE,
	postTranslations,
	careerTranslations,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [lineLeftPosition, setLineLeftPosition] = useState(0);
	const [lineWidth, setLineWidth] = useState(0);
	const [activeClass, setActiveClass] = useState("position_top");
	const [accessibilityMenu, setAccessibilityMenu] = useState(false);

	useEffect(() => {
		initialUnderline();

		$(window).resize(() => {
			initialUnderline();
		});

		if (typeof window !== "undefined") {
			window.addEventListener("scroll", () => {
				let activeClass = "shrink";
				if (window.scrollY === 0) {
					activeClass = "position_top";
				}

				setActiveClass(activeClass);
			});
		}
	}, []);

	const setUnderline = (e) => {
		const menuLeftPosition =
			e.target.parentElement.getBoundingClientRect().x;

		let leftLinePosition =
				e.target.getBoundingClientRect().x - menuLeftPosition,
			LineWidth = e.target.getBoundingClientRect().width;

		if ($(window).width() >= 1650) {
			LineWidth = LineWidth - 34;
			leftLinePosition = leftLinePosition + 17;
		} else if ($(window).width() >= 1200) {
			LineWidth = LineWidth - 30;
			leftLinePosition = leftLinePosition + 15;
		} else if ($(window).width() >= 992) {
			LineWidth = LineWidth - 10;
			leftLinePosition = leftLinePosition + 5;
		}

		setLineLeftPosition(leftLinePosition);
		setLineWidth(LineWidth);
	};

	const initialUnderline = () => {
		if ($(".menu__item--active").length > 0) {
			let leftInitialPosition = $(".menu__item--active").position().left,
				initialLineWidth = $(".menu__item--active").outerWidth();

			if ($(window).width() >= 1650) {
				initialLineWidth = initialLineWidth - 34;
				leftInitialPosition = leftInitialPosition + 17;
			} else if ($(window).width() >= 1200) {
				initialLineWidth = initialLineWidth - 30;
				leftInitialPosition = leftInitialPosition + 15;
			} else if ($(window).width() >= 992) {
				initialLineWidth = initialLineWidth - 10;
				leftInitialPosition = leftInitialPosition + 5;
			}

			setLineLeftPosition(leftInitialPosition);
			setLineWidth(initialLineWidth);
		} else {
			setLineLeftPosition(0);
			setLineWidth(0);
		}
	};

	return (
		<header
			className={`header ${isHome ? "header--home" : ""} ${
				isMenuOpen ? `menu_open ${activeClass}` : `${activeClass}`
			}`}
		>
			<div className="container container--big">
				<div className="row">
					<div className="col-8 col-sm-8 col-lg-2 header__logo-holder">
						<Link className="logo-holder__link" to={t("/", lang)}>
							<Logo2 />
						</Link>
					</div>

					<div className="col-4 col-sm-4 col-lg-10 col-xxl-9 offset-xxl-1 header__menu-holder">
						<div
							className={` header__menu menu ${
								isMenuOpen ? " header__menu--open" : ""
							} `}
						>
							<Link
								to={t("/", lang)}
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={setUnderline}
								onMouseLeave={initialUnderline}
							>
								{t("Strona główna", lang)}
							</Link>
							<Link
								to={t("/oferta/", lang)}
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={(e) => setUnderline(e)}
								onMouseLeave={(e) => initialUnderline()}
							>
								{t("Oferta", lang)}
							</Link>
							<Link
								to={t("/certyfikaty/", lang)}
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={(e) => setUnderline(e)}
								onMouseLeave={(e) => initialUnderline()}
							>
								{t("Certyfikaty", lang)}
							</Link>
							<Link
								to={t("/o-firmie/", lang)}
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={(e) => setUnderline(e)}
								onMouseLeave={(e) => initialUnderline()}
							>
								{t("O firmie", lang)}
							</Link>
							<Link
								to={t("/aktualnosci/", lang)}
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={(e) => setUnderline(e)}
								onMouseLeave={(e) => initialUnderline()}
							>
								{t("Aktualności", lang)}
							</Link>
							<Link
								to={t("/baza-wiedzy/", lang)}
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={(e) => setUnderline(e)}
								onMouseLeave={(e) => initialUnderline()}
							>
								{t("Baza wiedzy", lang)}
							</Link>
							<Link
								to={t("/rekrutacja/", lang)}
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={(e) => setUnderline(e)}
								onMouseLeave={(e) => initialUnderline()}
							>
								{t("Rekrutacja", lang)}
							</Link>
							<Link
								to={t("/kontakt/", lang)}
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={(e) => setUnderline(e)}
								onMouseLeave={(e) => initialUnderline()}
							>
								{t("Kontakt", lang)}
							</Link>
							<a
								href="https://verto.palettenwerk.pl/next-instance/"
								activeclassname="menu__item--active"
								className="menu__item"
								onMouseEnter={(e) => setUnderline(e)}
								onMouseLeave={(e) => initialUnderline()}
								target="_blank"
								rel="noreferrer"
							>
								{t("Panel Klienta", lang)}
							</a>
							{postTranslations ? (
								<div className="header__lang">
									<div className="header__lang-inner">
										<span>{lang}</span>
										{postTranslations.map((node, index) => (
											<Link
												to={`${
													node.language.slug !== "pl"
														? "/" +
														  node.language.slug
														: ""
												}/${node.slug}/`}
												key={index}
											>
												{node.language.code}
											</Link>
										))}
									</div>
									<svg viewBox="0 0 24 24">
										<path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
									</svg>
								</div>
							) : careerTranslations ? (
								<div className="header__lang">
									<div className="header__lang-inner">
										<span>{lang}</span>
										{careerTranslations.map(
											(node, index) => (
												<Link
													to={`${
														node.language.slug !==
														"pl"
															? "/" +
															  node.language.slug
															: ""
													}/${
														node.language.slug ===
														"en"
															? "recruitment"
															: node.language
																	.slug ===
															  "de"
															? "rekrutierung"
															: "rekrutacja"
													}/${node.slug}/`}
													key={index}
												>
													{node.language.code}
												</Link>
											)
										)}
									</div>
									<svg viewBox="0 0 24 24">
										<path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
									</svg>
								</div>
							) : (
								<div className="header__lang">
									<div className="header__lang-inner">
										{lang === "pl" ? (
											<>
												<Link to={translationPL}>
													PL
												</Link>
												<Link to={translationEN}>
													EN
												</Link>
												<Link to={translationDE}>
													DE
												</Link>
											</>
										) : lang === "en" ? (
											<>
												<Link to={translationEN}>
													EN
												</Link>
												<Link to={translationPL}>
													PL
												</Link>
												<Link to={translationDE}>
													DE
												</Link>
											</>
										) : lang === "de" ? (
											<>
												<Link to={translationDE}>
													DE
												</Link>
												<Link to={translationPL}>
													PL
												</Link>
												<Link to={translationEN}>
													EN
												</Link>
											</>
										) : (
											""
										)}
									</div>
									<svg viewBox="0 0 24 24">
										<path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
									</svg>
								</div>
							)}
							<span
								className="menu__underline"
								style={{
									left: lineLeftPosition,
									width: lineWidth,
								}}
							></span>
							<button
								className="header__accessibility"
								onClick={() => setAccessibilityMenu(true)}
							>
								<img
									src={require("../assets/img/ico-accessibility.svg")}
									alt=""
								/>
							</button>
							<OutsideClickHandler
								onOutsideClick={() =>
									setAccessibilityMenu(false)
								}
							>
								<AccessibilityMenu
									accessibilityMenu={accessibilityMenu}
								/>
							</OutsideClickHandler>
						</div>
						<button
							className={`header__burger ${
								isMenuOpen ? " header__burger--open" : ""
							}`}
							onClick={() => setIsMenuOpen(!isMenuOpen)}
						>
							<span></span>
							<span></span>
							<span></span>
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
