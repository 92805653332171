/* eslint-disable no-unused-vars */

import "./styles.scss";

import React, { useState, useEffect } from "react";

const AccessibilityMenu = ({ accessibilityMenu }) => {
	// // Accessibility options
	const [fontSize, setFontSize] = useState("regular");
	const [contrast, setContrast] = useState("low");
	const [newRoot, setNewRoot] = useState(null);

	// Defining <html> as root
	const setRoot = () => {
		const root = document.getElementsByTagName("html")[0];
		setNewRoot(root);
	};

	// Font size
	const reduceFontSize = () => {
		switch (fontSize) {
			case "bigger":
				setFontSize("big");
				localStorage.setItem("font-size", "big");
				setNewRoot(newRoot.setAttribute("font", "big"));
				break;

			case "big":
				setFontSize("regular");
				localStorage.setItem("font-size", "regular");
				setNewRoot(newRoot.setAttribute("font", "regular"));
				break;

			default:
				return null;
		}
	};

	const enlargeFontSize = () => {
		switch (fontSize) {
			case "regular":
				setFontSize("big");
				localStorage.setItem("font-size", "big");
				setNewRoot(newRoot.setAttribute("font", "big"));
				break;

			case "big":
				setFontSize("bigger");
				localStorage.setItem("font-size", "bigger");
				setNewRoot(newRoot.setAttribute("font", "bigger"));
				break;

			default:
				return null;
		}
	};

	// Contrast
	const enableContrast = () => {
		setContrast("high");
		localStorage.setItem("contrast", "high");
		setNewRoot(newRoot.setAttribute("contrast", "high"));
	};

	const disableContrast = () => {
		setContrast("low");
		localStorage.setItem("contrast", "low");
		setNewRoot(newRoot.setAttribute("contrast", "low"));
	};

	// Reseting all settings
	const resetCurrentSettings = () => {
		// Font size
		setFontSize("regular");
		localStorage.setItem("font-size", "regular");
		setNewRoot(newRoot.setAttribute("font", "regular"));

		// Contrast
		setContrast("low");
		localStorage.setItem("contrast", "low");
		setNewRoot(newRoot.setAttribute("contrast", "low"));
	};

	useEffect(() => {
		setRoot();
		// Root attributes
		newRoot &&
			newRoot.setAttribute(
				"font",
				localStorage.getItem("font-size")
					? localStorage.getItem("font-size")
					: "regular"
			);
		newRoot &&
			newRoot.setAttribute(
				"contrast",
				localStorage.getItem("contrast")
					? localStorage.getItem("contrast")
					: "low"
			);
		// State set
		setFontSize(
			localStorage.getItem("font-size")
				? localStorage.getItem("font-size")
				: "regular"
		);
		setContrast(
			localStorage.getItem("contrast") &&
				localStorage.getItem("contrast") === "low"
				? "low"
				: "high"
		);
	}, [newRoot]);

	return (
		<div
			className={`accessibility-menu${
				!accessibilityMenu ? " accessibility-menu--hidden" : ""
			}`}
		>
			<div className="accessibility-menu__font">
				<span className="accessibility-menu__label">
					Zmień rozmiar czcionki
				</span>
				<button
					type="button"
					className="accessibility-menu__button"
					onClick={reduceFontSize}
				>
					-
				</button>
				<button
					type="button"
					className="accessibility-menu__button"
					onClick={enlargeFontSize}
				>
					+
				</button>
			</div>

			<div className="accessibility-menu__contrast">
				<span className="accessibility-menu__label">
					Wybierz kontrast
				</span>
				<button
					type="button"
					className="accessibility-menu__button"
					onClick={disableContrast}
				>
					Aa
				</button>
				<button
					type="button"
					className="accessibility-menu__button accessibility-menu__button--hight-contrast"
					onClick={enableContrast}
				>
					Aa
				</button>
			</div>

			<button
				type="button"
				onClick={resetCurrentSettings}
				className="btn"
				data-hover="Zresetuj"
			>
				Zresetuj <span className="btn-arrows"></span>
			</button>
		</div>
	);
};

export default AccessibilityMenu;
