import React from "react";
import { Link } from "gatsby";

import Logo2 from "../components/logo2-white";
import IcoPlay from "../assets/img/ico-play.svg";

import t from "../locale";

const Footer = ({ isHome, lang }) => {
  return (
    <footer className={`footer ${isHome ? " footer--home" : ""}`}>
      <div className="footer__holder background--textColor">
        <div className="container container--big">
          <div className="row">
            <div className="col-12 mb-25">
              <Link className="footer__logo" to={t("/", lang)}>
                <Logo2 />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 footer__col">
              <h5 className="color--white mb-10">{t("Centrala", lang)}</h5>
              <p className="xs color--white mb-15">
                {t("Palettenwerk Kozik Sp. z o.o.", lang)}
                <br />
                ul. Przemysłowa 219 <br />
                34-240 Jordanów
                <br />
                <span className="footer__separator"></span>
                NIP: 944-224-48-96
                <br />
                KRS: 0000966492
                <br />
                REGON: 122861621
              </p>
              <a
                href="tel:+182773530"
                className="footer__link footer__link--mobile color-btn--white xs"
              >
                +18 2773 530
              </a>
              <a
                href="mailto:palety@palettenwerk.pl"
                className="footer__link footer__link--mobile color-btn--white xs"
              >
                palety@palettenwerk.pl
              </a>
            </div>
            <div className="col-6 col-md-6 col-lg-3 footer__col">
              <h5 className="color--white mb-10">{t("Oddział", lang)}</h5>
              <p className="xs color--white mb-15">
                PALKO sp. z o.o.
                <br />
                Gniewięcin 190
                <br />
                28-340 Sędziszów
                <br />
                <span className="footer__separator"></span>
                NIP: 735-002-69-43
                <br />
                KRS: 0000118040
                <br />
                REGON: 490443717
              </p>
              <a
                href="tel:+48604592700"
                className="footer__link footer__link--mobile color-btn--white xs"
              >
                +48 604 592 700
              </a>
              <a
                href="mailto:palety@palettenwerk.pl"
                className="footer__link footer__link--mobile color-btn--white xs"
              >
                palety@palettenwerk.pl
              </a>
            </div>
            <div className="col-6 col-md-6 col-lg-3 footer__col">
              <p className="xs color--white mb-15">
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-xxl-2 offset-xxl-1 footer__col">
              <div className="footer__menu footer-menu">
                <Link to={t("/", lang)} className="menu__item color-btn--white">
                  {t("Strona główna", lang)}
                </Link>
                <Link
                  to={t("/oferta/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Oferta", lang)}
                </Link>
                <Link
                  to={t("/certyfikaty/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Certyfikaty", lang)}
                </Link>
                <Link
                  to={t("/o-firmie/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("O firmie", lang)}
                </Link>
                <Link
                  to={t("/aktualnosci/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Aktualności", lang)}
                </Link>
                <Link
                  to={t("/baza-wiedzy/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Baza wiedzy", lang)}
                </Link>
                <Link
                  to={t("/kontakt/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Kontakt", lang)}
                </Link>
                <Link
                  to={t("/produkty/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Produkty", lang)}
                </Link>
                <Link
                  to={t("/oferta/jakosc/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Jakość", lang)}
                </Link>
                <Link
                  to={t("/oferta/dostawa/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Dostawa", lang)}
                </Link>
                <Link
                  to={t("/inne/", lang)}
                  className="menu__item color-btn--white"
                >
                  {t("Inne", lang)}
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 footer__col footer__col--desktop">
              <a
                href="tel:+182773530"
                className="footer__link color-btn--white xs"
              >
                +18 2773 530
              </a>
              <a
                href="mailto:palety@palettenwerk.pl"
                className="footer__link color-btn--white xs"
              >
                palety@palettenwerk.pl
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg-3 footer__col footer__col--desktop">
              <a
                href="tel:+48604592700"
                className="footer__link color-btn--white xs"
              >
                +48 604 592 700
              </a>
              <a
                href="mailto:palety@palettenwerk.pl"
                className="footer__link color-btn--white xs"
              >
                palety@palettenwerk.pl
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-xxl-2 offset-xxl-1 footer__col">
              <div className="footer__video">
                <a
                  href={
                    lang === "pl"
                      ? "https://www.youtube.com/watch?v=mbpGiOvlc2k"
                      : "https://www.youtube.com/watch?v=MXC7F6nGRRc"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IcoPlay} alt="" /> {t("Proces produkcyjny", lang)}
                </a>
              </div>
            </div>
          </div>
          <div className="row footer__copy">
            <div className="col-12 col-sm-6">
              <p className="copy__left copy color--white">
                &copy; PalettenWerk {new Date().getFullYear()}
              </p>
            </div>
            <div className="col-12 col-sm-6 copy__design">
              <p className="copy color--white design__text">
                {t("Projekt i wykonanie", lang)}
              </p>
              <a href="https://adream.pl/" target="_blank" rel="noreferrer">
                <img src={require("../assets/img/Adream.svg")} alt="Adream" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer__ue">
        <div className="container">
          <div className="row">
            <div className="col-12 ue__holder">
              <a
                href="https://www.funduszeeuropejskie.gov.pl/"
                target="_blank"
                rel="noreferrer"
                className="ue__item"
              >
                <UeSvg viewBox="0 0 670.6 181.4" />
              </a>
              <Link
                className="btn btn--small ue__item"
                to={t("/dotacje-ue/", lang)}
              >
                {t("Zrealizowane projekty unijne", lang)}
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
