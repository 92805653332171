const tranlations_en = {
  // Menu
  "Strona główna": "Home",
  "/": "/en/",
  Oferta: "Our services",
  "/oferta/": "/en/our-services/",
  Certyfikaty: "Certificates",
  "/certyfikaty/": "/en/certificates/",
  "O firmie": "About us",
  "/o-firmie/": "/en/about-us/",
  Aktualności: "News",
  "/aktualnosci/": "/en/news/",
  "Baza wiedzy": "Knowledge base",
  "/baza-wiedzy/": "/en/knowledge-base/",
  Rekrutacja: "Recruitment",
  "/rekrutacja/": "/en/recruitment/",
  Kontakt: "Contact us",
  "/kontakt/": "/en/contact-us/",
  Produkty: "Products",
  "/produkty/": "/en/products/",
  Jakość: "Quality",
  "/oferta/jakosc/": "/en/our-services/quality/",
  Dostawa: "Delivery",
  "/oferta/dostawa/": "/en/our-services/delivery/",
  "Panel Klienta": "Client's Panel",
  Inne: "Others",
  // Footer
  Centrala: "Headquarters",
  "Palettenwerk Kozik Sp. z o.o.": "Palettenwerk Kozik Sp. z o.o.",
  Oddział: "Branch",
  "Projekt i wykonanie": "Project and development",
  "Zrealizowane projekty unijne": "Completed EU projects",
  "/dotacje-ue/": "/en/eu-grants/",
  "Proces produkcyjny": "Production process",
  "Dowiedz się więcej o naszym procesie produkcyjnym":
    "Learn more about our production process",

  // Other
  "Czytaj więcej": "Read more",
  "lat na rynku": "years on the market",
  "Zobacz więcej": "Learn more",
  Akceptuje: "Accept",
  Wydarzenia: "Events",
  Ogłoszenia: "Announcements",
  "Lat doświadczenia <br /> na rynku palet":
    "years of experience <br/> in the pallet market",
  "w krajach Europy <br /> wysyłki": "countries in Europe delivery",
  "wykwalifikowanych <br /> pracowników": " qualified <br /> employees",
  "ha powierzchni <br /> magazynu": "ha of storage <br /> space",
  "wyprodukowane palety": "manufactured pallets",
  "załadowane tiry": "loaded HGVs",
  "zrealizowane zamówienia": "completed orders",
  "przerobione m3 drewna": "processed cubic metres of wood ",
  Home: "Home",
  "Osoby zainteresowane pracą prosimy o wysłanie CV na adres":
    "Osoby zainteresowane pracą prosimy o wysłanie CV na adres",
  Aplikuj: "Aplikuj",
  "Zapraszamy <br /> do współpracy":
    "We are looking forward <br /> to cooperating with you",
  "Serdecznie zapraszamy do kontaktu. Zakłady produkcyjne i magazyny mieszczą się w dwóch lokalizacjach: w Jordanowie w województwie małopolskim (centrala PalettenWerk) oraz w Gniewięcinie w województwie świętokrzyskim (pod marką Palko)":
    "Feel free to contact us. Our production plants and warehouses are located in two sites: in Jordanów in the Małopolskie voivodship (PalettenWerk head office) and in Gniewięcin in the Świętokrzyskie voivodship (under the Palko brand).",
  "Produkujemy także domy prefabrykowane":
    "We also manufacture prefabricated houses",
  Archiwum: "Archive",
  Data: "Date",
  Kategoria: "Category",
  Poprzedni: "Previous",
  Następny: "Next",
  // Kontakt
  Imię: "Name",
  Nazwisko: "Surname",
  Email: "Email address",
  Telefon: "Telephone number",
  "Treść wiadomości": "Message",
  "*Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez PalettenWerk, w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej":
    "*I voluntarily consent to the processing of my personal data by PalettenWerk for the purpose of direct marketing of its products and services. The obligation to provide information comes from Article 13 of the General Data Protection Regulation (GDPR) of 27 April 2016. (Journal of EU Law, 119, 04/05/2016): The information about how we process data and about the rights of people whose data we process can be found in our",
  "Korzystamy z plików cookies w celu optymalizacji usług. Jeśli korzystasz z naszej witryny bez zmiany ustawień dotyczących cookies oznacza, że wyrażasz zgodę na zamieszczanie ich przez nasw Twoim urządzeniu. ":
    "We use cookie files in order to optimize our web services. If you are using our website without changing your default web browser's settings regarding cookies, it means you permit their use on your device. ",
  "polityce prywatności": "privacy policy",
  "/polityka-prywatnosci/": "/en/privacy-policy/",
  "polityka cookies": "cookies policy",
  "/polityka-cookies/": "/en/cookies-policy/",
  Wyślij: "Send",
  "...wysyłanie": "...sending",
  "/o-firmie/#30-lat-na-rynku": "/en/about-us/#30-years-on-the-market",

  "Przełomowy projekt na rynku logistycznym":
    "Groundbreaking project on the logistics market.",
  Sprawdź: "Read more",
};
export default tranlations_en;
