const tranlations_pl = {
  // Menu
  "Strona główna": "Strona główna",
  "/": "/",
  Oferta: "Oferta",
  "/oferta/": "/oferta/",
  Certyfikaty: "Certyfikaty",
  "/certyfikaty/": "/certyfikaty/",
  "O firmie": "O firmie",
  "/o-firmie/": "/o-firmie/",
  Aktualności: "Aktualności",
  "/aktualnosci/": "/aktualnosci/",
  "Baza wiedzy": "Baza wiedzy",
  "/baza-wiedzy/": "/baza-wiedzy/",
  Rekrutacja: "Rekrutacja",
  "/rekrutacja/": "/rekrutacja/",
  Kontakt: "Kontakt",
  "/kontakt/": "/kontakt/",
  Produkty: "Produkty",
  "/produkty/": "/produkty/",
  Jakość: "Jakość",
  "/oferta/jakosc/": "/oferta/jakosc/",
  Dostawa: "Dostawa",
  Inne: "Inne",
  "/oferta/dostawa/": "/oferta/dostawa/",
  "Panel Klienta": "Panel Klienta",
  // Footer
  Centrala: "Centrala",
  "Palettenwerk Kozik Sp. z o.o.": "Palettenwerk Kozik Sp. z o.o.",
  Oddział: "Oddział",
  "Projekt i wykonanie": "Projekt i wykonanie",
  "Zrealizowane projekty unijne": "Zrealizowane projekty unijne",
  "/dotacje-ue/": "/dotacje-ue/",
  "Proces produkcyjny": "Proces produkcyjny",
  "Dowiedz się więcej o naszym procesie produkcyjnym":
    "Dowiedz się więcej o naszym procesie produkcyjnym",

  // Other
  "Czytaj więcej": "Czytaj więcej",
  "lat na rynku": "lat na rynku",
  "Zobacz więcej": "Zobacz więcej",
  Akceptuje: "Akceptuje",
  Wydarzenia: "Wydarzenia",
  Ogłoszenia: "Ogłoszenia",
  "Lat doświadczenia <br /> na rynku palet":
    "Lat doświadczenia <br /> na rynku palet",
  "w krajach Europy <br /> wysyłki": "w krajach Europy <br /> wysyłki",
  "wykwalifikowanych <br /> pracowników":
    "wykwalifikowanych <br /> pracowników",
  "ha powierzchni <br /> magazynu": "ha powierzchni <br /> magazynu",
  "wyprodukowane palety": "wyprodukowane palety",
  "załadowane tiry": "załadowane tiry",
  "zrealizowane zamówienia": "zrealizowane zamówienia",
  "przerobione m3 drewna": "przerobione m3 drewna",
  Home: "Home",
  "Osoby zainteresowane pracą prosimy o wysłanie CV na adres":
    "Osoby zainteresowane pracą prosimy o wysłanie CV na adres",
  Aplikuj: "Aplikuj",
  "Zapraszamy <br /> do współpracy": "Zapraszamy <br /> do współpracy",
  "Serdecznie zapraszamy do kontaktu. Zakłady produkcyjne i magazyny mieszczą się w dwóch lokalizacjach: w Jordanowie w województwie małopolskim (centrala PalettenWerk) oraz w Gniewięcinie w województwie świętokrzyskim (pod marką Palko)":
    "Serdecznie zapraszamy do kontaktu. Zakłady produkcyjne i magazyny mieszczą się w dwóch lokalizacjach: w Jordanowie w województwie małopolskim (centrala PalettenWerk) oraz w Gniewięcinie w województwie świętokrzyskim (pod marką Palko)",
  "Produkujemy także domy prefabrykowane":
    "Produkujemy także domy prefabrykowane",
  Archiwum: "Archiwum",
  Data: "Data",
  Kategoria: "Kategoria",
  Poprzedni: "Poprzedni",
  Następny: "Następny",
  // Kontakt
  Imię: "Imię",
  Nazwisko: "Nazwisko",
  Email: "Email",
  Telefon: "Telefon",
  "Treść wiadomości": "Treść wiadomości",
  "*Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez PalettenWerk, w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej":
    "*Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez PalettenWerk, w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej",
  "Korzystamy z plików cookies w celu optymalizacji usług. Jeśli korzystasz z naszej witryny bez zmiany ustawień dotyczących cookies oznacza, że wyrażasz zgodę na zamieszczanie ich przez nasw Twoim urządzeniu.":
    "Korzystamy z plików cookies w celu optymalizacji usług. Jeśli korzystasz z naszej witryny bez zmiany ustawień dotyczących cookies oznacza, że wyrażasz zgodę na zamieszczanie ich przez nasw Twoim urządzeniu. ",
  "polityce prywatności": "polityce prywatności",
  "/polityka-prywatnosci/": "/polityka-prywatnosci/",
  "polityka cookies": "polityka cookies",
  "/polityka-cookies/": "/polityka-cookies/",
  Wyślij: "Wyślij",
  "...wysyłanie": "...wysyłanie",
  "/o-firmie/#30-lat-na-rynku": "/o-firmie/#30-lat-na-rynku",

  "Przełomowy projekt na rynku logistycznym":
    "Przełomowy projekt na rynku logistycznym",
  Sprawdź: "Sprawdź",
};

export default tranlations_pl;
