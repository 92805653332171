/* eslint-disable jsx-a11y/control-has-associated-label */

import "../assets/styles/main.scss";

import React, { Component } from "react";
import { CookiesProvider, withCookies } from "react-cookie";
import { Link } from "gatsby";

import $ from "jquery";
import t from "../locale";

import Header from "./header";
import Footer from "./footer";
import SEO from "./seo";

class Layout extends Component {
	state = {
		cookieOpen: false,
		banerOpen: false,
	};

	componentDidMount() {
		const { cookies } = this.props;

		const isAcceptedCoookie = !!cookies.get("cookie-accept-paletten");
		!isAcceptedCoookie && this.setState({ cookieOpen: true });

		const isAcceptedBaner = !!cookies.get("palletenBanerPallet4");
		!isAcceptedBaner && this.setState({ banerOpen: true });

		$("body").on("contextmenu", "img", function (e) {
			return false;
		});
	}

	acceptCookie = () => {
		const { cookies } = this.props;

		const promiseSetCookie = new Promise((resolve) =>
			resolve(
				cookies.set("cookie-accept-paletten", "active", { path: "/" })
			)
		);
		promiseSetCookie.then(() => {
			this.setState({ cookieOpen: false });
		});
	};

	acceptBaner = () => {
		const { cookies } = this.props;

		const promiseSetCookie = new Promise((resolve) =>
			resolve(
				cookies.set("palletenBanerPallet4", "active", { path: "/" })
			)
		);
		promiseSetCookie.then(() => {
			this.setState({ banerOpen: false });
		});
	};

	render() {
		const {
			children,
			seoTitle,
			isHome,
			lang,
			translationPL,
			translationEN,
			translationDE,
			postTranslations,
			careerTranslations,
			isPallet4,
		} = this.props;

		return (
			<CookiesProvider>
				<SEO title={seoTitle} />
				<Header
					isHome={isHome}
					lang={lang}
					translationPL={translationPL}
					translationEN={translationEN}
					translationDE={translationDE}
					postTranslations={postTranslations}
					careerTranslations={careerTranslations}
				/>
				<main className="main">{children}</main>
				<Footer isHome={isHome} lang={lang} />
				{/*
				<div
					className={`cookie-baner${
						this.state.cookieOpen ? " open" : ""
					}`}
					id="mainBelt"
				>
					<p>
						{t(
							"Korzystamy z plików cookies w celu optymalizacji usług. Jeśli korzystasz z naszej witryny bez zmiany ustawień dotyczących cookies oznacza, że wyrażasz zgodę na zamieszczanie ich przez nasw Twoim urządzeniu. ",
							lang
						)}
						<a
							href={t("/polityka-cookies/", lang)}
							target="_blank"
							rel="noreferrer"
						>
							{t("Czytaj więcej", lang)}
						</a>
					</p>
					<button
						className="btn"
						onClick={this.acceptCookie}
						data-hover={t("Akceptuje", lang)}
					>
						{t("Akceptuje", lang)}
						<span className="btn-arrows"></span>
					</button>
				</div>
		  */}
				{!isPallet4 && this.state.banerOpen && (
					<div
						className={`baner-pallet${
							this.state.banerOpen ? " open" : ""
						}`}
					>
						<button onClick={this.acceptBaner}></button>
						<div className="baner-pallet__content">
							<img
								src={require("../assets/img/baner-pallet-4-image.png")}
								alt=""
							/>

							<h4>Paleta 4.0</h4>
							<p>
								{t(
									"Przełomowy projekt na rynku logistycznym",
									lang
								)}
							</p>
						</div>
						<div className="baner-pallet__cta">
							<Link
								to={
									lang === "pl"
										? "/oferta/paleta-4-0/"
										: lang === "de"
										? ""
										: ""
								}
								target="_blank"
								rel="noreferrer"
								className="btn"
								data-hover={t("Sprawdź", lang)}
							>
								{t("Sprawdź", lang)}
								<span className="btn-arrows"></span>
							</Link>
						</div>
					</div>
				)}
			</CookiesProvider>
		);
	}
}

export default withCookies(Layout);
