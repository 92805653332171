import tranlations_pl from "./pl";
import tranlations_en from "./en";
import tranlations_de from "./de";

const t = (name, lang = "en") => {
  lang = lang.toLowerCase();
  try {
    switch (lang) {
      case "pl":
        return tranlations_pl[name] || name;
      case "en":
        return tranlations_en[name] || name;
      case "de":
        return tranlations_de[name] || name;
      default:
        return name;
    }
  } catch (error) {
    return name;
  }
};
export default t;
