const tranlations_de = {
	// Menu
	"Strona główna": "Hauptseite",
	"/": "/de/",
	Oferta: "Angebot",
	"/oferta/": "/de/angebot/",
	Certyfikaty: "Zertifikate",
	"/certyfikaty/": "/de/zertifikate/",
	"O firmie": "Über uns",
	"/o-firmie/": "/de/uber-uns/",
	Aktualności: "Aktuelles",
	"/aktualnosci/": "/de/aktuelles/",
	"Baza wiedzy": "Wissensdatenbank",
	"/baza-wiedzy/": "/de/wissensdatenbank/",
	Rekrutacja: "Rekrutierung",
	"/rekrutacja/": "/de/rekrutierung/",
	Kontakt: "Kontakt",
	"/kontakt/": "/de/kontakt/",
	Produkty: "Produkte",
	"/produkty/": "/de/produkte/",
	Jakość: "Qualität",
	"/oferta/jakosc/": "/de/angebot/qualitat/",
	Dostawa: "Lieferung",
	"/oferta/dostawa/": "/de/angebot/lieferung/",
	"Panel Klienta": "Kundenpanel",
	// Footer
	Centrala: "Zentrale",
	"Palettenwerk Kozik Sp. z o.o.": "Palettenwerk Kozik Sp. z o.o.",
	Oddział: "Filiale",
	"Projekt i wykonanie": "Design und Ausführung",
	"Zrealizowane projekty unijne": "Abgewickelte EU-Projekte",
	"/dotacje-ue/": "/eu-zuschusse/",
	"Proces produkcyjny": "Verfahren Produktion",
	"Dowiedz się więcej o naszym procesie produkcyjnym":
		"rfahren Sie mehr über unseren Produktionsprozess",

	// Other
	"Czytaj więcej": "Weiterlesen",
	"lat na rynku": "Jahre auf dem Markt",
	"Zobacz więcej": "Mehr sehen",
	Akceptuje: "Ich bin einverstanden",
	Wydarzenia: "Veranstaltungen",
	Ogłoszenia: "Anzeigen",
	"Lat doświadczenia <br /> na rynku palet":
		"Jahre Erfahrung <br /> auf dem Palettenmarkt",
	"w krajach Europy <br /> wysyłki": "Versandländer in Europa",
	"wykwalifikowanych <br /> pracowników": "qualifizierte <br /> Mitarbeiter",
	"ha powierzchni <br /> magazynu": "ha Lagerfläche",
	"wyprodukowane palety": "hergestellte Paletten",
	"załadowane tiry": "beladene Lkw",
	"zrealizowane zamówienia": "abgewickelte Aufträge",
	"przerobione m3 drewna": "verarbeitete m3 von Holz ",
	Home: "Home",
	"Osoby zainteresowane pracą prosimy o wysłanie CV na adres":
		"Osoby zainteresowane pracą prosimy o wysłanie CV na adres",
	Aplikuj: "Aplikuj",
	"Zapraszamy <br /> do współpracy":
		"Wir laden zur <br /> Zusammenarbeit ein",
	"Serdecznie zapraszamy do kontaktu. Zakłady produkcyjne i magazyny mieszczą się w dwóch lokalizacjach: w Jordanowie w województwie małopolskim (centrala PalettenWerk) oraz w Gniewięcinie w województwie świętokrzyskim (pod marką Palko)":
		"Wir freuen uns auf den Kontakt mit Ihnen. Die Produktionsstätten und Lager befinden sich an zwei Standorten: in Jordanów in der Woiwodschaft Kleinpolen (Hauptsitz der Firma PalettenWerk) und in Gniewięcin in der Woiwodschaft Heiligkreuz (unter der Marke Palko).",
	"Produkujemy także domy prefabrykowane":
		"Wir stellen auch Fertighäuser her ",
	Archiwum: "Archiv",
	Data: "Datum",
	Kategoria: "Kategorie",
	Poprzedni: "Zurück",
	Następny: "Weiter",
	// Kontakt
	Imię: "Vorname",
	Nazwisko: "Nachname",
	Email: "Email",
	Telefon: "Telefon",
	"Treść wiadomości": "Inhalt der Nachricht",
	"*Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez PalettenWerk, w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej":
		"*Ich erkläre mich freiwillig mit der Verarbeitung meiner personenbezogenen Daten durch PalettenWerk zum Zwecke des Direktmarketings meiner eigenen Produkte und Dienstleistungen einverstanden. Informationspflicht nach Art. 13 der Allgemeinen Verordnung zum Schutz personenbezogener Daten (DSGVO) vom 27. April 2016 (Journal of Laws UE L 119 vom 04.05.2016): Informationen zur Verarbeitung von Daten durch uns sowie zu den Rechten betroffener Personen finden Sie in unsere",
	"Korzystamy z plików cookies w celu optymalizacji usług. Jeśli korzystasz z naszej witryny bez zmiany ustawień dotyczących cookies oznacza, że wyrażasz zgodę na zamieszczanie ich przez nasw Twoim urządzeniu. ":
		"PalettenWerk nutzen Cookies, damit unsere Sites zuverlässig und sicher laufen, wir deren Performance auf dem Schirm behalten und um dir relevante Inhalte und personalisierte Werbung zu zeigen. Damit das funktioniert, sammeln wir Daten über unsere Nutzer und wie sie unsere Angebote auf welchen Geräten nutzen. ",
	"polityce prywatności": "Datenschutzerklärung",
	"/polityka-prywatnosci/": "/de/datenschutzerklarung/",
	"polityka cookies": "cookie politik",
	"/polityka-cookies/": "/de/cookie-politik/",
	Wyślij: "Senden",
	"...wysyłanie": "...senden",
	"/o-firmie/#30-lat-na-rynku": "/de/uber-uns/#30-jahre-auf-dem-markt",

	"Przełomowy projekt na rynku logistycznym":
		"Ein bahnbrechendes Projekt auf dem Logistikmarkt",
	Sprawdź: "Weiterlesen",
};
export default tranlations_de;
