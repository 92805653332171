import React from "react";

const Logo2White = () => {
  return (
    <>
      <svg viewBox="0 0 378 69">
        <defs>
          <linearGradient
            x1="-116.875926%"
            y1="7.91291391%"
            x2="137.239352%"
            y2="71.9307947%"
            id="linearGradient-1"
          >
            <stop stopColor="#3AB88C" offset="0%"></stop>
            <stop stopColor="#21A38A" offset="19.68%"></stop>
            <stop stopColor="#0B9088" offset="34.95%"></stop>
            <stop stopColor="#079C89" offset="41.97%"></stop>
            <stop stopColor="#00B08A" offset="50%"></stop>
            <stop stopColor="#0BB28C" offset="54.79%"></stop>
            <stop stopColor="#28B790" offset="63.18%"></stop>
            <stop stopColor="#57BF98" offset="74.1%"></stop>
            <stop stopColor="#69C29B" offset="77.96%"></stop>
            <stop stopColor="#3DB885" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="-26.8695652%"
            y1="31.6780731%"
            x2="238.294203%"
            y2="95.9083056%"
            id="linearGradient-2"
          >
            <stop stopColor="#3AB88C" offset="0%"></stop>
            <stop stopColor="#21A38A" offset="19.68%"></stop>
            <stop stopColor="#0B9088" offset="34.95%"></stop>
            <stop stopColor="#079C89" offset="41.97%"></stop>
            <stop stopColor="#00B08A" offset="50%"></stop>
            <stop stopColor="#0BB28C" offset="54.79%"></stop>
            <stop stopColor="#28B790" offset="63.18%"></stop>
            <stop stopColor="#57BF98" offset="74.1%"></stop>
            <stop stopColor="#69C29B" offset="77.96%"></stop>
            <stop stopColor="#3DB885" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="logo30_1">
            <g id="Group" transform="translate(0.000000, 7.000000)">
              <path
                d="M110,40.8 L105.2,40.8 L110.3,16.7 L114.4,16.7 C118.9,16.7 125,16.9 125,23 C125,28.8 120.2,31.7 114.8,31.7 L112,31.7 L110,40.8 Z M112.8,27.9 L114.5,27.9 C117.2,27.9 119.9,26.5 119.9,23.5 C119.9,21 117.9,20.5 115.9,20.5 L114.4,20.5 L112.8,27.9 Z"
                id="Shape"
                fill="#ffffff"
                fillRule="nonzero"
              ></path>
              <path
                d="M134,38.1 C132.3,40.1 130.7,41.3 128.2,41.3 C125,41.3 122.7,39.5 122.7,36.2 C122.7,30.3 129.3,29.9 133.6,29.9 L135.2,29.9 C135.3,29.4 135.3,28.9 135.3,28.4 C135.3,26.6 133.4,26.1 131.7,26.1 C129.7,26.1 127.7,26.6 125.9,27.5 L126.6,23.8 C128.6,23.1 130.6,22.7 132.7,22.7 C136.3,22.7 139.6,24 139.6,28.2 C139.6,30.6 137.8,37.6 137.4,40.9 L133.5,40.9 L134,38.1 Z M129.5,37.9 C133,37.9 134,35.5 134.8,32.6 L133.2,32.6 C130.9,32.6 127.2,33 127.2,36 C127.1,37.2 128.3,37.9 129.5,37.9 Z"
                id="Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <polygon
                id="Path"
                fill="#ffffff"
                points="145.7 14.9 150.4 14.9 144.9 40.8 140.2 40.8"
              ></polygon>
              <path
                d="M153,33.4 C153,36.7 154.8,37.9 157.9,37.9 C159.8,37.9 161.6,37.4 163.4,36.6 L162.8,40.3 C161,40.9 159,41.3 157.1,41.3 C152,41.3 148.5,38.8 148.5,33.5 C148.5,28 152.8,22.6 158.6,22.6 C163,22.6 165.9,24.8 165.9,29.4 C165.9,30.8 165.7,32.1 165.4,33.4 L153,33.4 Z M161.5,30.2 C161.6,29.8 161.6,29.4 161.6,29 C161.6,27.1 160.6,26 158.7,26 C155.8,26 154.6,27.7 153.7,30.2 L161.5,30.2 Z"
                id="Shape"
                fill="#ffffff"
                fillRule="nonzero"
              ></path>
              <path
                d="M167.6,23 L171,23 L171.8,19.3 L176.8,17.7 L175.7,23 L179.9,23 L179.2,26.4 L175.1,26.4 L173.4,34.2 C173.3,34.6 173.2,35.2 173.2,35.9 C173.2,37 173.9,37.6 175,37.6 C175.8,37.6 176.7,37.4 177.2,37.2 L176.4,40.8 C175.6,41 174.7,41.2 173.9,41.2 C170.8,41.2 168.5,40 168.5,36.5 C168.5,35.1 168.8,33.8 169.1,32.4 L170.4,26.4 L167,26.4 L167.6,23 Z"
                id="Path"
                fill="#ffffff"
              ></path>
              <path
                d="M178.5,23 L181.9,23 L182.7,19.3 L187.7,17.7 L186.6,23 L190.7,23 L190,26.4 L185.9,26.4 L184.2,34.2 C184.1,34.6 184,35.2 184,35.9 C184,37 184.7,37.6 185.8,37.6 C186.6,37.6 187.5,37.4 188.1,37.2 L187.3,40.8 C186.5,41 185.6,41.2 184.8,41.2 C181.7,41.2 179.4,40 179.4,36.5 C179.4,35.1 179.7,33.8 180,32.4 L181.3,26.4 L177.9,26.4"
                id="Path"
                fill="#ffffff"
              ></path>
              <path
                d="M193.3,33.4 C193.3,36.7 195.1,37.9 198.2,37.9 C200.1,37.9 201.9,37.4 203.7,36.6 L203.1,40.3 C201.3,40.9 199.3,41.3 197.4,41.3 C192.3,41.3 188.8,38.8 188.8,33.5 C188.8,28 193.1,22.6 198.9,22.6 C203.3,22.6 206.2,24.8 206.2,29.4 C206.2,30.8 206,32.1 205.8,33.4 L193.3,33.4 Z M201.8,30.2 C201.9,29.8 201.9,29.4 201.9,29 C201.9,27.1 200.9,26 199,26 C196.1,26 194.9,27.7 194,30.2 L201.8,30.2 Z"
                id="Shape"
                fill="#ffffff"
                fillRule="nonzero"
              ></path>
              <path
                d="M209.6,25.6 C209.8,24.5 210,23.6 210.1,23 L214.6,23 L214.3,24.7 L214.4,24.7 C215.7,23.5 217.4,22.6 219.4,22.6 C222.9,22.6 225.2,24.8 225.2,28.3 C225.2,29.7 224.9,31.1 224.6,32.2 L222.7,40.8 L218.1,40.8 L219.8,32.4 C220,31.2 220.3,30 220.3,28.7 C220.3,27.2 219.3,26.2 217.8,26.2 C214.6,26.2 213.4,29.2 212.8,31.9 L210.9,40.9 L206.3,40.9 L209.6,25.6 Z"
                id="Path"
                fill="#ffffff"
              ></path>
              <polygon
                id="Path"
                fill="#ffffff"
                points="227.4 16.7 229.7 16.7 231.4 38.1 231.5 38.1 242.1 16.7 244.9 16.7 246.3 38.1 246.4 38.1 257.1 16.7 259.5 16.7 247.2 40.8 244.4 40.8 243.1 19.4 243 19.4 232.4 40.8 229.5 40.8"
              ></polygon>
              <path
                d="M257.7,32.6 C257.6,33.1 257.6,33.5 257.6,34 C257.6,37.4 259.8,39.3 263,39.3 C265.2,39.3 266.8,38.7 268.2,38.1 L268,40.2 C267,40.6 265.6,41.1 262.7,41.1 C258.6,41.1 255.4,38.6 255.4,33.9 C255.4,28 258.9,22.6 264.5,22.6 C268.7,22.6 270.7,25.2 270.7,29.2 C270.7,30.3 270.6,31.4 270.5,32.5 L257.7,32.5 L257.7,32.6 Z M268.5,30.8 C268.5,30.3 268.6,29.9 268.6,29.5 C268.6,26.6 267.4,24.7 264.6,24.7 C261.2,24.7 258.7,27.6 258.1,30.8 L268.5,30.8 Z"
                id="Shape"
                fill="#ffffff"
                fillRule="nonzero"
              ></path>
              <path
                d="M273.8,26.3 C274,25.3 274.2,24.3 274.3,23.2 L276.5,23.2 C276.4,24.3 276.3,25.2 276.1,25.9 L276.2,25.9 C277.3,24.2 278.8,22.8 281.1,22.8 C281.8,22.8 282.4,22.9 283,23 L282.5,25.3 C281.9,25.1 281.3,25 280.6,25 C277,25 275.6,28.9 275,32.1 L273.2,40.8 L270.9,40.8 L273.8,26.3 Z"
                id="Path"
                fill="#ffffff"
              ></path>
              <polygon
                id="Path"
                fill="#ffffff"
                points="286.1 14.9 288.3 14.9 285 30.3 285 30.3 294.2 23.2 297.4 23.2 286.8 31 294.7 40.8 291.8 40.8 284.7 31.8 282.7 40.8 280.5 40.8"
              ></polygon>
              <path
                d="M51.6,0.4 L91.6,8.9 L89.9,16.9 L49.9,8.4 L5.4,21.2 L7.1,13.2 L51.6,0.4 Z M4.6,25.2 L2.9,33.2 L47.4,20.4 L87.4,28.9 L89.1,20.9 L49.1,12.4 L4.6,25.2 Z"
                id="Shape"
                fill="#008C6A"
                fillRule="nonzero"
              ></path>
              <path
                d="M40.3,53.6 L0.3,45.1 L2,37.1 L42,45.6 L86.5,32.8 L84.8,40.8 L40.3,53.6 Z M87.3,28.8 L89,20.8 L44.5,33.7 L4.5,25.2 L2.8,33.2 L42.8,41.7 L87.3,28.8 Z"
                id="Shape"
                fill="#F48120"
                fillRule="nonzero"
              ></path>
            </g>
            <rect
              id="Rectangle"
              fill="#ffffff"
              x="306"
              y="0.5"
              width="1"
              height="68.4"
            ></rect>
            <path
              d="M368.4,52.4 C367.6,53.3 366.9,53.8 365.7,53.8 C364.2,53.8 363.2,53 363.2,51.5 C363.2,48.8 366.2,48.6 368.2,48.6 L368.9,48.6 C369,48.4 369,48.1 369,47.9 C369,47.1 368.1,46.8 367.4,46.8 C366.5,46.8 365.6,47 364.7,47.4 L365,45.7 C365.9,45.4 366.8,45.2 367.8,45.2 C369.4,45.2 371,45.8 371,47.7 C371,48.8 370.2,52 370,53.5 L368.2,53.5 L368.4,52.4 Z M366.3,52.3 C367.9,52.3 368.4,51.2 368.7,49.9 L368,49.9 C367,49.9 365.3,50.1 365.3,51.5 C365.3,52 365.8,52.3 366.3,52.3 Z"
              id="Shape"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
            <polygon
              id="Path"
              fill="#FFFFFF"
              points="362.1 41.8 364.2 41.8 361.7 53.7 359.5 53.7"
            ></polygon>
            <path
              d="M372.1,45.5 L373.6,45.5 L374,43.8 L376.3,43.1 L375.8,45.5 L377.7,45.5 L377.4,47.1 L375.5,47.1 L374.7,50.7 C374.7,50.9 374.6,51.2 374.6,51.5 C374.6,52 374.9,52.3 375.4,52.3 C375.8,52.3 376.2,52.2 376.4,52.1 L376,53.8 C375.6,53.9 375.2,54 374.9,54 C373.5,54 372.4,53.4 372.4,51.9 C372.4,51.3 372.5,50.6 372.7,50 L373.3,47.2 L371.8,47.2 L372.1,45.5 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
            <g id="Group" transform="translate(316.000000, 18.000000)">
              <path
                d="M25,26.6 C23.1,24 22.2,20.3 22.2,15.3 C22.2,10.4 23.1,6.7 25,4.1 C26.9,1.5 29.5,0.3 33,0.3 C36.5,0.3 39.2,1.6 41,4.1 C42.9,6.7 43.8,10.4 43.8,15.3 C43.8,20.2 42.9,24 41,26.6 C39.1,29.2 36.5,30.5 33,30.5 C29.5,30.5 26.8,29.2 25,26.6 Z M37.2,23.6 C38.1,21.9 38.5,19.1 38.5,15.3 C38.5,11.5 38.1,8.8 37.2,7.1 C36.3,5.4 34.9,4.5 33,4.5 C31.1,4.5 29.7,5.4 28.8,7.1 C27.9,8.8 27.5,11.6 27.5,15.3 C27.5,19.1 27.9,21.9 28.8,23.6 C29.7,25.3 31.1,26.2 33,26.2 C34.9,26.2 36.3,25.4 37.2,23.6 Z"
                id="Shape"
                fill="url(#linearGradient-1)"
                fillRule="nonzero"
              ></path>
              <path
                d="M19.3,17.5 C20.2,18.7 20.7,20.3 20.7,22.1 C20.7,24.7 19.8,26.7 17.9,28.2 C16,29.7 13.5,30.4 10.2,30.4 C8.2,30.4 6.3,30.1 4.5,29.5 C2.7,28.9 1.2,28 0,27 L1.8,23.1 C4.4,25.1 7.1,26.2 10,26.2 C11.9,26.2 13.3,25.8 14.2,25.1 C15.1,24.4 15.6,23.2 15.6,21.7 C15.6,20.2 15.1,19.1 14.2,18.4 C13.3,17.7 11.8,17.3 9.9,17.3 L6,17.3 L6,13 L9.2,13 C13,13 14.9,11.6 14.9,8.7 C14.9,7.4 14.5,6.3 13.7,5.6 C12.9,4.9 11.7,4.5 10.2,4.5 C8.9,4.5 7.6,4.8 6.2,5.3 C4.8,5.8 3.5,6.6 2.2,7.6 L0.4,3.7 C1.6,2.6 3.1,1.8 4.9,1.2 C6.7,0.6 8.6,0.3 10.5,0.3 C13.4,0.3 15.7,1 17.4,2.4 C19.1,3.8 20,5.7 20,8.1 C20,9.7 19.5,11.2 18.7,12.4 C17.9,13.6 16.7,14.5 15.3,15 C17.1,15.4 18.4,16.2 19.3,17.5 Z"
                id="Path"
                fill="url(#linearGradient-2)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default Logo2White;
